import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { IMAGES } from "../constants/theme";

const LoginHeader = () => {
    const [headerFix, setheaderFix] = React.useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setheaderFix(window.scrollY > 50);
        });
    }, []);
    return (
        <>
            <header className="site-header mo-left header header-transparent style-1">
                <div className="top-bar">
                    <div className="container">
                        <div className="dz-topbar-inner d-flex justify-content-between align-items-center">
                            {/* <div className="dz-topbar-left">
                                <ul>
                                    <li>
                                        <i className="fa-regular fa-envelope"></i> <a href="mailto:info@culinarymedicinesolutions.org">info@culinarymedicinesolutions.org</a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div
                    className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""
                        }`}
                >
                    <Mainheader />
                </div>
                {/* <!-- Main Header End --> */}
            </header>
        </>
    );
};

export default LoginHeader;

export const Mainheader = () => {
    /* for sticky header */

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("");
    const location = useLocation();

    useEffect(() => {
        var mainMenu = document.getElementById("OpenMenu");
        if (mainMenu) {
            if (sidebarOpen) {
                mainMenu.classList.add("show");
            } else {
                mainMenu.classList.remove("show");
            }
        }
    });

    // Menu dropdown list
    const reducer = (previousState, updatedState) => ({
        ...previousState,
        ...updatedState,
    });
    const initialState = {
        active: "",
        activeSubmenu: "",
    };
    return (
        <>
            <div className="main-bar clearfix">
                <div className="container clearfix">
                    <div className="box-header clearfix">
                        {/* <!-- Website Logo --> */}
                        <div className="logo-header mostion logo-dark">
                            <Link to={"/"}>
                                <img className="select_logo" src={IMAGES.logo} alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
