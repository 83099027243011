import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { IMAGES, PROJECT } from '../../constants/theme';

function Solution() {
    const Food_and_Product_Innovation = {
        id: 1,
        h1: 'Food and Product Innovation',
        p: [
            `Discover our passion and commitment to pioneering food and product innovation that aligns with our mission of promoting health through food at scale. We strive to develop cutting-edge culinary medicine solutions that not only taste great and highlight  sustainability and equity, our product nourish the body and mind for a positive impact on health in the UK. `,
            `Sign up to be the first to hear when our products go live `
            //  `<a href ="https://form.typeform.com/to/k9hPKG5r" target="_blank" rel="noopener">Click here to give feedback on our new product after testing.</a>`
        ]
    };
    const Culinary_Medicine_Consulting = {
        id: 2,
        h1: 'Culinary Medicine Consulting',
        p:
            [
                `Contact us for transformative projects integrating nutrition into healthcare practices and championing sustainability across industry in the UK. Our consulting services offer tailored solutions to elevate patient care and drive positive change, both within and beyond the healthcare sector.`
            ]
    };
    const Events_and_Menu_Development = {
        id: 3,
        h1: 'Events and Menu Development',
        p: [
            `Explore our expertise in curating engaging events and crafting menus that prioritise health and well-being for diverse audiences, including healthcare organisations and businesses. From interactive workshops to menu planning, we specialise in creating culinary medicine experiences with our dedicated team of professional chefs, dietitians and doctors that inspire healthier choices and sustainable practices.`
        ]
    }
    const Team_Building_Activities = {
        id: 4,
        h1: 'Team Building Activities',
        p: [
            `Engage with our team building activities designed to foster collaboration, communication, and well-being among professionals across various sectors, including healthcare organisations. Through interactive sessions and culinary experiences, we create opportunities for teams to bond, learn, and grow together, promoting a culture of health and wellness beyond traditional boundaries. We can host events at sites in the UK or at the London Centre of Culinary Medicine UK in Westminster `
        ]
    }
    const Investing_in_Culinary_Medicine_UK = {
        id: 5,
        h1: 'Investing in Culinary Medicine UK',
        p: [
            `For every project undertaken with Culinary Medicine Solutions, a percentage will be reinvested into Culinary Medicine UK, our Community Interest Company (CIC) dedicated to educating healthcare professionals to translate nutrition into practice to enhance patient care and advance culinary medicine initiatives. `,
            `Income generated through Culinary Medicine Solutions support our valuable work at Culinary Medicine UK CIC`,
            `Join us on this journey towards a healthier, more sustainable world with Culinary Medicine Solutions. `,
        ]
    }
    const [solutions, setSolutions] = useState(Food_and_Product_Innovation);
    const [active, setActive] = useState(1);
    const handleSelect = (number) => {
        if (number === 1) {
            setActive(1);
            setSolutions(Food_and_Product_Innovation)
        }
        if (number === 2) {
            setActive(2);
            setSolutions(Culinary_Medicine_Consulting);
        }
        if (number === 3) {
            setActive(3);
            setSolutions(Events_and_Menu_Development)
        }
        if (number === 4) {
            setActive(4);
            setSolutions(Team_Building_Activities);
        }
        if (number === 5) {
            setActive(5);
            setSolutions(Investing_in_Culinary_Medicine_UK)
        }
    }
    return (
        <section>
            <div
                className="content-inner "
                style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7 order-1">
                            <div className="dz-media m-b30">
                                <img src={PROJECT.Solutions} alt="" />
                            </div>
                            <div className="dz-content">
                                <div className="m-b40">
                                    <h2 className="title m-b15">
                                        {/* Providing world class services */}
                                        {solutions.h1}
                                    </h2>
                                    {solutions.p.map((data, index) => (
                                        <p key={index} dangerouslySetInnerHTML={{ __html: data }} ></p>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5 m-b30">
                            <aside className="side-bar left sticky-top">
                                <div className="widget service_menu_nav">
                                    <ul>
                                        {/* <li className={`${active === 1 ? 'active' : ''} `} onClick={(e) => handleSelect(1)}> */}
                                        <li className={`${active === 1 ? 'active' : ''} `} >
                                            <Link to={"#"} onClick={(e) => handleSelect(1)}>Food and Product Innovation</Link>{" "}
                                        </li>
                                        <li className={`${active === 2 ? 'active' : ''} `}>
                                            <Link to={"#"} onClick={(e) => handleSelect(2)} >Culinary Medicine Consulting</Link>
                                        </li>
                                        <li className={`${active === 3 ? 'active' : ''} `}>
                                            <Link to={"#"} onClick={(e) => handleSelect(3)}>Events and Menu Development</Link>{" "}
                                        </li>
                                        <li className={`${active === 4 ? 'active' : ''} `}>
                                            <Link to={"#"} onClick={(e) => handleSelect(4)}>Team Building Activities</Link>{" "}
                                        </li>
                                        <li className={`${active === 5 ? 'active' : ''} `}>
                                            <Link to={"#"} onClick={(e) => handleSelect(5)}>Investing in Culinary Medicine UK</Link>{" "}
                                        </li>
                                    </ul>
                                    <svg
                                        width="250"
                                        height="70"
                                        viewBox="0 0 250 70"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 38L250 0L210 70L0 38Z"
                                            fill="url(#paint0_linear_306_1296)"
                                        ></path>
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_306_1296"
                                                x1="118.877"
                                                y1="35.552"
                                                x2="250.365"
                                                y2="35.552"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop offset="1" stopColor="var(--primary)"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Solution