export const MenuListArray2 = [
    {
        title: 'Home',
        to: '/',
        id: 'Home',
    },
    {
        title: 'Services',
        to: '/',
        id: 'services'
    },
    {
        title: 'Our Team',
        to: '/',
        id: 'our-team'
    },

    {
        title: 'Contact us',
        to: '/',
        id: 'contact-us'
    },

]