import React, { useEffect, useRef, useState } from 'react';
// import { Dropdown } from 'react-bootstrap';
//import swal from "sweetalert";
import { IMAGES } from '../../constants/theme';
import { Link, useNavigate } from 'react-router-dom';
import LoginHeader from '../../layouts/loginheader';
import Swal from 'sweetalert2';
import axios from 'axios';
const LoginEmail = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const form = useRef();
    const sendEmail = async (e) => {
        e.preventDefault();
        console.log(email);
        const regex = /^[0-9]{6,13}$/;
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            Swal.fire({ icon: 'info', text: "Please Provide Valid Email" });
            return;
        }
        let data = new FormData();
        data.append('email', email);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://culinarymedicinesolutions.org/api/app.php',
            maxRedirects: 0,
            data: data
        };
        try {
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
            if (response.data.status === "success") {
                // Swal.fire({ icon: 'success', text: response.data.message });
                navigate('/login')
            }
            else {
                Swal.fire({ icon: 'error', text: 'please try again ' });
            }
        }
        catch (error) {
            Swal.fire({ icon: 'error', text: 'Please try again' });
        }
    };
    const token = localStorage.getItem('token');
    useEffect(() => {
        if (token !== null) {
            navigate('/')
        }
    }, [])
    return (
        <>
            <div className="page-wraper" >

                {/* <Header /> */}

                <LoginHeader />
                <div className="page-content bg-white " >
                    <section className="appointment-page" data-text="HEALTH" style={{ backgroundImage: "url(" + IMAGES.BgAppoint + ")" }}>
                        <div className="container">
                            <div className="section-head mb-0 margin-head ">
                                <h2 className="title text-center pt-lg-4 mt-lg-4 ">Welcome to <span className="text-primary">Culinary Medicine </span>Solutions</h2>
                                <h2 className="title text-center mt-lg-5 mb-lg-5">Launching <span className="text-primary">Soon </span></h2>
                                <h5 className='text-center mt-lg-5 '>Be the first to know when we're live in 2024</h5>
                                {/* <h4 className='text-center p-lg-3'>Signup here, enter your email address to see what's coming soon and register your interest to hear when our services go live</h4> */}

                                <h5 className='text-center p-lg-3'>Enter your email to register your interest and find out more about services going live later in 2024</h5>

                                <h5
                                    className='text-center'>
                                    At Culinary Medicine Solutions, we are dedicated to transforming the healthcare industry globally through nutrition and sustainability. With a team deeply rooted in the esteemed<Link style={{ color: "#FF3700" }} to="https://culinarymedicineuk.org/" target="_blank" rel="noopener"> Culinary Medicine UK</Link>, we bring innovative solutions that harmonise food and health in the UK and beyond.
                                </h5>
                            </div>
                            {/* <form className="appointment-form dzForm d-flex justify-content-center" ref={form} onSubmit={sendEmail}>
                                <div className='form-group'>
                                    <input className='form-control' type='email' />
                                </div>
                                <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg btn-skew"><span>Appointment</span></button>
                            </form> */}
                            <div className="call-action style-1">

                                <div className="inner-content wow fadeInUp" data-wow-delay="0.8s">
                                    <div className="row justify-content-between align-items-center">
                                        {/* <div className="text-center text-lg-start col-xl-6 m-lg-b20">
                                            <h2 className="title">Subscribe To Our Newsletter</h2>
                                            <p>It is a long established fact that a reader will distracted.</p>
                                        </div> */}
                                        <div className="text-center text-lg-end offset-xl-3 col-xl-6">
                                            <form className="dzSubscribe" ref={form} onSubmit={sendEmail}>
                                                <div className="dzSubscribeMsg"></div>
                                                <div className="form-group mb-0">
                                                    <div className="input-group mb-0">
                                                        <div className="input-skew " style={{ border: '2px solid black' }}>
                                                            <input name="dzEmail" required="required" type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your Email Address" />
                                                        </div>
                                                        <div className="input-group-addon">
                                                            <button name="submit"
                                                                //value="Submit" 
                                                                type="submit" className="btn btn-secondary btn-lg btn-skew"><span>Subscribe Now</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

            </div>
            <footer className="site-footer style-1 bg-img-fix footer-action" id="footer">
                <div className="footer-bottom">
                    <div className="text-center">
                        <span className="copyright-text">Copyright © 2024 <Link to="/"  > Culinary Medicine Solutions. </Link>. All rights reserved.</span>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default LoginEmail;