import React from 'react';
import { IMAGES } from '../constants/theme';
import { Link } from 'react-router-dom';


const logoBlog = [
    { logo: IMAGES.logo1 },
    { logo: IMAGES.logo2 },
    { logo: IMAGES.logo3 }
];

const iconDropBlog = [
    { title: "Best Hand Workout", id: 'icon1' },
    { title: "Best Knee Workout", id: 'icon2' },
    { title: "Best Leg Workout", id: 'icon3' },
];

const MainBanner = ({ isOpenModal }) => {
    // const [iconTitle, setIconTitle] = useState();
    // const [isOpen, setOpen] = useState(false);

    return (
        <>
            {/* <div className="banner-inner" style={{ backgroundImage: "url(" + IMAGES.SliderBg1 + ")" }}> */}
            <div className="banner-inner " style={{ backgroundImage: "url(" + IMAGES.SliderBg1 + ")" }}>
                {/* <h2 className="data-text">
                    <span>C</span>
                    <span>U</span>
                    <span>L</span>
                    <span>I</span>
                    <span>N</span>
                    <span>A</span>
                    <span>R</span>
                    <span>Y</span>
                </h2> */}
                <div className="container">
                    <div className="row banner-row">
                        {/* <div className="col-lg-6 col-md-7 col-sm-8"> */}
                        <div className="col-md-12 col-sm-12">
                            <div className="banner-content">
                                <div className="top-content m-0">
                                    <h1 className="title ">Welcome to <span className="text-primary">Culinary Medicine </span>Solutions</h1>
                                    <h1 className='title h2'>Launching  <span className="text-primary">Soon</span></h1>
                                    <p className='text-center justifypara' >At Culinary Medicine Solutions, we are dedicated to transforming the healthcare industry globally through nutrition and sustainability. With a team deeply rooted in the esteemed<Link to="https://culinarymedicineuk.org/" target="_blank" rel="noopener"> Culinary Medicine UK</Link>, we bring innovative solutions that harmonise food and health in the UK.</p>
                                    {/* <div className="d-flex align-items-center">
                                        <Link to={"/about-us"} className="btn btn-skew btn-lg btn-primary shadow-primary"><span>Get Started</span></Link>
                                        <div className="video-bx4">
                                            <Link to={"#"} className="video-btn style-1 popup-youtube"
                                                onClick={() => isOpenModal(true)} >
                                                <i className="fa fa-play" />{" "}
                                                <span className="text">Play Video</span>
                                            </Link>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="bottom-content">
                                    <h4 className="partner-title" data-wow-delay="0.8s">Our Partner</h4>
                                    <div className="row">
                                        {logoBlog.map((data, ind) => (
                                            <div className="col-4" key={ind}>
                                                <div className="clients-logo">
                                                    <img src={data.logo} alt="" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-5 col-sm-4">
                            <div className="banner-media media1 anm wow fadeInRight" data-wow-delay="1s" data-speed-x="-2" data-speed-scale="-1">
                                <img src={IMAGES.sliderpic1} className="main-img" alt="" />
                                <ul className="point-list">
                                    {iconDropBlog.map((item, ind) => (
                                        <li
                                            className={`icon-dropdown anm ${item.id === iconTitle ? 'show' : ''}`}
                                            data-speed-x="-1" data-speed-scale="-1"
                                            onClick={() => {
                                                setIconTitle(item.id)
                                                if (item.id === iconTitle) {
                                                    setIconTitle('')
                                                }
                                            }}
                                            key={ind}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                            <span>{item.title}</span>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    );
};

export default MainBanner;