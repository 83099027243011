import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
// import NewsLetter from "../elements/NewsLetter";
// import PageTitle from "../elements/PageTitle";

const ContactUs = () => {
  const [userData, setUserData] = useState({ fname: '', lname: '', email: '', phone: '', message: '' });
  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();
    const regex = /^[0-9]{6,13}$/;
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
      Swal.fire({ icon: 'info', text: "Please Provide Valid Email" });
      return
    }
    if (!regex.test(userData.phone)) {
      Swal.fire({ icon: 'error', text: 'Please Provide Valid Phone Number' });
      return
    }

    let data = new FormData();
    data.append('first_name', userData.fname);
    data.append('last_name', userData.lname);
    data.append('email', userData.email);
    data.append('phone', userData.phone);
    data.append('message', userData.message);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://culinarymedicinesolutions.org/api/info.php',
      maxRedirects: 0,
      data: data
    };
    try {
      const response = await axios.request(config);
      if (response.data.status === "success") {
        Swal.fire({ icon: 'success', text: 'Message sent successfully' });
        console.log(JSON.stringify(response.data));
        setUserData({ fname: '', lname: '', email: '', phone: '', message: '' })
      }
      else {
        Swal.fire({ icon: 'error', text: 'Message not sent Please try again' });
      }
    }
    catch (error) {
      Swal.fire({ icon: 'error', text: 'Message not sent Please try again' });
    }

  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(e.target)
    setUserData((prev) => ({
      ...prev, [name]: value
    }))
  }

  return (
    <>
      <div className="page-content bg-white" style={{ paddingBottom: '80px' }}>
        {/* <PageTitle activePage="Contact Us" parentTitle="Home" /> */}
        <section className="content-inner-2 z-index-none">

          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-xl-5 m-sm-b30 m-xl-b0">
                <div className="contact-box">
                  <h3 className="contact-title">Contact Information</h3>
                  <p className="contact-text">
                    Please update to kindly complete the form below for enquiries and allow 3 business working days for replies.
                  </p>
                  <div className="widget widget_getintuch ">
                    <ul>
                      <li>{" "}</li>
                      <li>{" "}</li>
                      <li>
                        <i className="fa-solid fa-envelope"></i>
                        <p><Link to="mailto:info@culinarymedicinesolutions.org">info@culinarymedicinesolutions.org</Link></p>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <br />
                  </div>
                  {/*  <h6 className="m-b15 text-white">Our Socials</h6>
                   <div className="dz-social-icon style-1 dark">
                    <ul>
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.facebook.com/"
                          rel="noreferrer"
                        >
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.instagram.com/"
                          rel="noreferrer"
                        >
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          target="_blank"
                          to="https://twitter.com/"
                          rel="noreferrer"
                        >
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          target="_blank"
                          to="https://whatsapp.com/"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-whatsapp" />
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <svg
                    width="250"
                    height="70"
                    viewBox="0 0 250 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 38L250 0L210 70L0 38Z"
                      fill="url(#paint0_linear_306_1296)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_306_1296"
                        x1="118.877"
                        y1="35.552"
                        x2="250.365"
                        y2="35.552"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="1" stopColor="var(--primary)" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="col-md-6 col-xl-7">
                <h1 className="text-center title " style={{ paddingBottom: '40px' }}>Contact Us</h1>
                <form
                  className="dz-form dzForm style-1"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  {/* <input
                    type="hidden"
                    className="form-control"
                    name="dzToDo"
                    value="Contact"
                  />
                  <div className="dzFormMsg"></div> */}

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-group input-line">
                        <input
                          name="fname"
                          required
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={userData.fname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-group input-line">
                        <input
                          name="lname"
                          required
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={userData.lname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                          name="email"
                          required
                          type="email"
                          className="form-control"
                          placeholder="Your Email Address"
                          value={userData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                          name="phone"
                          required
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          value={userData.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="input-group input-line m-b30">
                        <textarea
                          name="message"
                          rows="5"
                          required
                          className="form-control"
                          placeholder="Message..."
                          value={userData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="btn btn-primary btn-lg btn-skew"
                      >
                        <span>Send Message</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="container content-inner-1">
          <div className="map-iframe">
            <iframe
              title="myFrame"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28896.531392443423!2d75.81462525569334!3d25.133445080066668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x537f208422eb8f28!2sW3ITEXPERTS%20-%20Software%20Development%20Company%20in%20kota!5e0!3m2!1sen!2sin!4v1669897446044!5m2!1sen!2sin"
              style={{ border: "0", marginBottom: "-7px", width: "100%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section> */}
      </div>
    </>
  );
};

export default ContactUs;
