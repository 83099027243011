import React from "react";

import { IMAGES, PROJECT } from "../../constants/theme";
import OurTeamModal from "./Modal";


const dataBlog = [
    {
        image1: PROJECT.drbaruah,
        image2: IMAGES.avatar1,
        author: "Jone Doe",
        title: "Dr Sumi Baruah",
        date: "17 May 2023",
        content: "MBBS BSc (Hons) DFSRH DRCOG MRCGP PGCert (Merit) FRSPH | Chief Executive Officer",
        description: [
            `Dr. Sumi Baruah, CEO of Culinary Medicine Solutions, is a practising GP and educator with a visionary approach to transforming healthcare through nutrition and sustainability. With a rich background in pioneering initiatives at Culinary Medicine UK, Dr. Baruah's journey is fuelled by a deep-seated commitment to revolutionise the intersection of food and health. Her experience in developing innovative culinary medicine projects at CMUK has inspired her to lead the change at Culinary Medicine Solutions, where she continues to spearhead groundbreaking projects that harmonise food, health, and well-being. Dr. Baruah's relentless dedication to empowering clinicians and patients with informed dietary choices underscores her mission to create a healthier, more sustainable world through Culinary Medicine Solutions.`
        ]
    },
    {
        image1: PROJECT.elaine,
        image2: IMAGES.avatar2,
        author: "Frenchi",
        title: "Elaine MacAninch",
        date: "18 May 2023",
        content: 'BSc (Hons) PGCE PGDip RD PhD Candidate | CMUK Nutrition and Education Lead and Director',
        description: [
            `Elaine MacAninch is a Registered Dietitian working in education and research. She has developed a nutrition programme for Brighton and Sussex Medical School, UCL medical school and is the education lead and director Culinary Medicine UK. She continues to have a clinical role specialising in diabetes in pregnancy. `,
            `She has published papers on the gaps in nutrition education for medical students, nutrition inequalities and helped to write the UK nutrition curriculum for medical doctors. She is passionate about improving equal access to appropriate healthy food for all. `
        ]
    },
    {
        image1: PROJECT.vince,
        image2: IMAGES.avatar3,
        author: "Culinary Lead",
        title: "Chef Vince Kelly",
        date: "17 May 2023",
        content: 'Culinary Lead <br /> <br /> <br /> ',
        description: [
            `Vince Kelly, the Head of Hospitality Skills Academy at the Mayor's Academy of Hospitality within the Capital City College Group, is a multifaceted professional with a wealth of experience in the hospitality industry. As a Co-director of Culinary Medicine UK, Vince leads culinary initiatives focused on promoting healthy food choices and sustainability. His commitment to reducing food waste is exemplified through his role as a Food Waste Ambassador, advocating for a healthier planet through conscious consumption practices. Vince's dedication to education is evident in his pioneering work in programs like "Healthy Plate, Healthy Planet" and the development of a bespoke degree in "Culinary Health and Nutrition." With over 30 years of industry expertise, Vince Kelly embodies a holistic approach that resonate with the ethos of Culinary Medicine Solutions.`
        ]
    },
];

function changeItemBoxed() {
    let bodyClass = document.body.classList;

    if (bodyClass.contains === "boxed") {
        return 3;
    } else {
        return 4;
    }
}


function CulinaryTeam() {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState({ name: '', image: '', designation: '', content: [], image: '' });
    const handleSelect = (data) => {
        setModalData({ name: data.title, designation: data.content, content: data.description, image: data.image1 });
        setModalShow(true)
    }
    return (
        <>
            <div className="container">
                <div className="row justify-content-between align-items-center m-b10">
                    <div className="col-xl-7">
                        <div className="section-head text-center text-md-start">
                            <h2 className="title">
                                Our  <span>Team</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {dataBlog.map((item, ind) => (
                        // <SwiperSlide key={ind}>
                        <div key={ind} className="col-lg-4 col-md-6" style={{ cursor: 'pointer' }} onClick={(e) => handleSelect(item)}>
                            <div className="dz-card style-1 overlay-shine">
                                <div className="dz-media">
                                    {/* <Link to={"/blog-details"}> */}
                                    <img src={item.image1} alt="" />
                                    {/* </Link> */}
                                </div>
                                <div className="dz-info">
                                    {/* <div className="dz-meta">
                                    <ul>
                                        <li className="post-author">
                                            <Link to={"#"}>
                                                <img src={item.image2} alt="" />
                                                <span>By {item.author}</span>
                                            </Link>{" "}
                                        </li>
                                        <li className="post-date">
                                            <Link to={"#"}> {item.date}</Link>
                                        </li>
                                    </ul>
                                </div> */}
                                    <h4 className="dz-title">
                                        {/* <Link to={"/blog-details"}> */}
                                        {item.title}
                                        {/* </Link> */}
                                    </h4>
                                    <p dangerouslySetInnerHTML={{ __html: item.content }}>
                                        {/* {item.content} */}
                                    </p>

                                    <button className="btn btn-primary btn-skew" onClick={(e) => handleSelect(item)}><span>Read More</span></button>
                                </div>
                            </div>
                        </div>
                        // </SwiperSlide>
                    ))}
                    {/* </Swiper> */}
                </div>
            </div>
            <OurTeamModal show={modalShow}
                onHide={() => setModalShow(false)} data={modalData} />
        </>
    );
}
export default CulinaryTeam;
