import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";

//Components
import MainBanner from "../components/MainBanner";
import { IMAGES, PROJECT, SVGICON } from "../constants/theme";
import Solution from "../components/culinary/solution";
import CulinaryTeam from "../components/culinary/team";
// import Timeline from "../components/culinary/timeline";
// import CulinaryService from "../components/culinary/culinaryservice";
// import ClunaryTeam from "../components/culinary/clunaryteam";
import ContactUs from "./ContactUs";

const Home = () => {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  useEffect(() => {
    const body = document.querySelector("body");
    body.setAttribute("data-theme-color", 'color_1');
    localStorage.setItem("theme", "color_1");
    localStorage.setItem("themeInd", 0);
  }, [location]);

  useEffect(() => {
    if (token === null) {
      navigate('/password');
    }
  }, [])


  return (
    <>

      <div className="page-content bg-white" >
        <div className="main-bnr-one " id="home" >
          <MainBanner isOpenModal={setOpen} />
        </div>
        <div id='services'>
          <Solution />
        </div>

        <section
          className="content-inner-1 overflow-hidden " id="our-team"
          style={{ backgroundImage: "url(" + IMAGES.BgImage1 + ")" }}
        >
          <CulinaryTeam />
        </section>
      </div>
      <div id="contact-us">
        <ContactUs />
      </div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="X_9VoqR5ojM"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Home;
