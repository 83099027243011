import React from 'react'
import Modal from 'react-bootstrap/Modal';


function OurTeamModal({ show, onHide, data }) {
    return (
        <Modal
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-modal"
        >
            {/* <Modal.Header closeButton>
            </Modal.Header> */}
            <Modal.Body >
                <div className='row' >
                    <div className='col-lg-3 col-md-12 col-12'>
                        <img src={data.image} />
                        <h4>{data.name}</h4>
                        <h6 dangerouslySetInnerHTML={{ __html: data.designation }}></h6>
                    </div>
                    {/* <div className='col-lg-9 col-md-12 col-12' style={{ height: '350px', overflowY: 'auto' }}> */}
                    <div className='col-lg-9 col-md-12 col-12' >
                        {/* <h4>{data.name}</h4> */}
                        {data.content.map((item, index) =>
                            <p key={index}>
                                {item}
                            </p>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={onHide}>Close</Button> */}
                <button className='btn btn-primary btn-skew' onClick={onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}

export default OurTeamModal